.returnBlogButton {
    width: fit-content;
    height: fit-content;
    display: flex;
    transition: 300ms;
    border: solid;
    border-width: 1px;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;

    padding: 7px;
    padding-right: 9px;
    padding-left: 9px;
    margin-top: 20px;
    position: absolute
}

.darkBlogBtn {
    background-color: black;
    border-color: whitesmoke;
    color: whitesmoke;
}

.darkBlogBtn:hover {
    background-color: whitesmoke;
    color: black;
}

.whiteBlogBtn {
    background-color: whitesmoke;
    color: black;
    border-color: black;
}


.whiteBlogBtn:hover {
    background-color: black;
    color: whitesmoke;
}



.returnBlogButtonText {
    height: fit-content;
    margin: 0;
}

.articleDiv {

    margin: 20px;
    margin-top: 10px
}