.articleDiv {
    font-family: Helvetica;
}

.articleDiv .bold {
    font-weight: 600;
    margin: 0
}

.articleTitle {
    margin-bottom: 50px;
}

.articleBody {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    gap: 30px
}

.articleProjectLink {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    gap: 50px;
}

.articleBodyImage {
    width: 40%;
    background-color: red;
    object-fit: cover;
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;

    border-width: 1px;
    border-radius: 18px;
}

.articleBodyText {
    size: 18px;
    text-align: justify;
}

.articleProjectLink div {
    width: fit-content;
    height: fit-content;
    display: flex;
    color: white;
    transition: 300ms;
    border-color: whitesmoke;
    border: solid;
    border-width: 1px;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    padding: 7px;
    padding-right: 9px;
    padding-left: 9px;
}

.articleProjectLink div:hover {
    background-color: whitesmoke;
    color: black;

}

.articleProjectLink div Text {
    height: fit-content;
    margin: 0;
}